import { isInRoles } from "./authorize.directive";
import Roles from "./roles";

export function textareaAutoGrow(evt) {
    let min = 35;
    if (evt.target.rows) {
      min = 21 * evt.target.rows
    }
    if (evt.target.scrollHeight > min) {
      min = evt.target.scrollHeight
    }
    if (!evt.target.style.maxHeight || evt.target.style.maxHeight < min) {
      evt.target.style.overflow = 'hidden';
    }
    evt.target.style.height = (min) + "px";
  }

export function generateUUID() {
    // Generate an array of 16 random bytes
    const array = new Uint8Array(16);
    window.crypto.getRandomValues(array);

    // Set the version to 4 (UUID version 4)
    array[6] = (array[6] & 0x0f) | 0x40;
    // Set the variant to 2 (UUID variant specified in RFC 4122)
    array[8] = (array[8] & 0x3f) | 0x80;

    // Convert the byte array to a UUID string
    const uuid = Array.from(array).map((byte, index) => {
        // Add hyphens at the appropriate positions
        if (index === 4 || index === 6 || index === 8 || index === 10) {
            return '-' + byte.toString(16).padStart(2, '0');
        }
        return byte.toString(16).padStart(2, '0');
    }).join('');

    return uuid;
}

export function printElem(divContent)
{
    // Create a new window
    const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;
      const printWindow = window.open('', '', `width=${screenWidth},height=${screenHeight},fullscreen=yes`);


    // Get the stylesheets
    let styles = '<style>';
      for (let i = 0; i < document.styleSheets.length; i++) {
        try {
          const rules = document.styleSheets[i].cssRules || document.styleSheets[i].rules;
          for (let j = 0; j < rules.length; j++) {
            styles += rules[j].cssText;
          }
        } catch (e) {
          console.warn("Could not load stylesheet: " + document.styleSheets[i].href, e);
        }
      }
      styles += '</style>';

    // Write the content to the new window
    printWindow.document.write('<html><head><title>Print</title>');
    printWindow.document.write(styles);
    printWindow.document.write('</head><body>');
    printWindow.document.write('<div>' + divContent.innerHTML + '</div>');
    printWindow.document.write('</body></html>');

    // Close the document to finish writing
    printWindow.document.close();

    // Wait for the content to load and then print
    printWindow.onload = function() {
      printWindow.print();
      printWindow.close();
    };
}

export function resolveLoginSuccess(router) {
  if (isInRoles(Roles.AcceptTerms)) {
    router.push({
      name: 'terms-and-conditions'
    });
  } else if (isInRoles(Roles.FillClientData)) {
    router.push({
      name: 'informacoes-adicionais-dados-basicos'
    });
  } else if (isInRoles(Roles.ResponderPesquisa)) {
    router.push({
      name: 'responder-pesquisa'
    });
  } else {
    router.push({
      path: '/'
    });
  }
} 


export function transformTextareaContentToHtml(content) {
    return content?.replace(/\n/g, '<br />') ?? '';
}

export function getIniciais(nome) {
  var split = (nome ?? 'Não identificado').split(' ');
  var iniciais = (nome ?? 'Não identificado')[0]
  if (split.length > 1) {
    iniciais += split[split.length - 1][0]
  }
  return iniciais.toUpperCase()
}

export function getLinkWhatsapp() {
  return `https://api.whatsapp.com/send/?phone=5511985082539&text=${encodeURI(`Olá, sou usuário da plataforma e me chamo ${localStorage.getItem('mention.username')}, da empresa ${localStorage.getItem('mention.cliente')} e gostaria de ajuda com a plataforma.`)}&type=phone_number&app_absent=0`;
}